import React, { useState, useEffect } from 'react';
import { TextField, FormControl, Checkbox, InputLabel, OutlinedInput, InputAdornment, IconButton, Icon} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import demo from "../images/demo.mov"
import demoImg from "../images/demoImg.png"
import fairOpp from "../images/fairOppWithLogo.png"
import $ from 'jquery';
import Alert from "@mui/material/Alert";

const Login = (props) => {
  const [checked, setChecked] = useState(true);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [eye, setEye] = useState(false);

  // Jquery to prevent demo video from playing on mobile
  $(function(){
    var screenWidth = $(window).width();
    if (screenWidth < 800){
      $('#demoVid').removeAttr('autoplay');
    } else {
      $('#demoVid').attr('autoplay');
    }
  });

  const handleEye = () => {
    setEye(!eye);
  }

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const updateEmail = (emailValue) => {
    setEmail(emailValue);
    if (emailValue === "") {
      setShowEmailError(true);
      setEmailErrorMsg("Email required")
    } else {
      setShowEmailError(false);
      setEmailErrorMsg("")
    }
  }

  const updatePassword = (passwordValue) => {
    setPassword(passwordValue);
    if (passwordValue === "") {
      setShowPasswordError(true);
      setPasswordErrorMsg("Pasword required");
    } else {
      setShowPasswordError(false);
      setPasswordErrorMsg("");
    }
  }

  
  async function handleLogin(event) {
    event.preventDefault()
    setEmailErrorMsg("");
    setPasswordErrorMsg("");
    setShowEmailError(false);
    setShowPasswordError(false);

    try {
      const formData = {
        email: email,
        password: password
      }
      const response = await fetch(props.backendURL + "login", {
        method: "POST", 
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      })

      const data = await response.json()
      if (response.ok) {
        props.setToken(data.access_token)
        setEmail("");
        setPassword("");

        window.location.href = props.frontendURL
      }
      else {
        if (data.email_error) {
          setEmailErrorMsg(data.msg)
          setShowEmailError(true)
        }
        if (data.password_error) {
          setPasswordErrorMsg(data.msg)
          setShowPasswordError(true)
        }
        if (!data.email_error && !data.password_error) {
          setEmailErrorMsg("Unexpected error occurred. Please try again!")
          setShowEmailError(true)
          setPasswordErrorMsg("Unexpected error occurred. Please try again!")
          setShowPasswordError(true)
        }
      }

    } catch (error) {
      if (error.response) {
        setEmailErrorMsg("Unexpected error. Please try again later")
        setShowEmailError(true)
        setPasswordErrorMsg("Unexpected error. Please try again later")
        setShowPasswordError(true)
      }
    }
  }

  return (
    <> 
      <div>
        <Alert severity="info"> Fin<font color="#5CB69E"><b>AI</b></font>d is currently trained with the updated 2025-26 FAFSA. Ask any question related to the FAFSA and get the most up to date answers. </Alert>
      </div>
      <div className='loginContainer'>
        <div className="demo">
          <video className="demoVid" id="demoVid" src={demo} autoPlay loop muted/>
        </div>
        <div className='infoContainer'>
            <div className='loginTitles'>
              <h1 className='loginTitle'>Get Financial Aid Help with GPT</h1>
              <h3 className='loginSubtitle'>Log in to your Fin<font color="#5CB69E"><b>AI</b></font>d account.</h3>
            </div>
            
            <div className='loginForm'>
              <TextField 
                label="Email"
                sx={{width: {xs: 200, sm: 300, md: 400, lg: 500},
                    marginBottom: 5,
                    marginTop: 5,
                    }}
                value={email}
                onChange={(e) => updateEmail(e.target.value)}
                error={showEmailError}
                helperText={emailErrorMsg}
                />
              <TextField 
                label="Password"
                type={eye?"text":"password"}
                sx={{width: {xs: 200, sm: 300, md: 400, lg: 500}}}
                value={password}
                onChange={(e) => updatePassword(e.target.value)}
                error={showPasswordError}
                helperText={passwordErrorMsg}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton onClick={handleEye}>
                      {eye?<VisibilityIcon/>:<VisibilityOffIcon/>}
                    </IconButton>
                  </InputAdornment>
                }}
                />
              <Link className='forgotPasswordLink' to="/forgotpassword">Forgot Password?</Link>
              <div className='checkbox'>
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  label = "Remember Me"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                />
                <h1 className='checkboxTitle'>Remember Me</h1>
              </div>

              <button className='loginButton' onClick={handleLogin}> Login </button>
              <Link className='signInButtonMobile' to="/signup"> New here? Sign Up</Link>
            </div>  
          </div>  
      </div>
    </> 
  );
}

export default Login;

